@font-size-base: 10em;

body {
  background-color: black;
  color: #c0daff;
  font-family: Quantico;
  width: 100vw;
  height: 100vh;
  display: flex;
}

#root {
       display: flex;
     }

.App {
       display: flex;
         width: 100vw;
         height: 100vh;
           flex-direction: column;
           align-items: center;
     }

.hero-ex {
  color: #c0daff;
  height: 15vh;
  min-width: 400px;
  margin-top: 5%;
  flex-direction: row;
}

.hero {
  padding: 2%;
  margin-bottom: 5px;
}

.hero-content {
  height: 15vh;
  display: flex;
  flex-wrap: row;
  justify-content: space-between;
}

.hero-content .logo {
  background-image: url("https://www.exitgame.fr/wp-content/uploads/2023/03/logor_trou.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 15vh;
  height: 15vh;
}

.hero-content .col {
  width: 33vw;
}

.hero-content .timer {
  background-color: #183d87;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 3vmin);
  margin-top: 30px;
  padding-top: 6px;
}

.topMessage {
  background-color: cornflowerblue;
  color: aliceblue;
  font-weight: bold;
}

.bloc-bug {
  width: 20vw;
  margin: 8px;
  padding: 4px;
  font-size: x-small;
  text-align: center;
  color:#bcbcbc;
  background-color:#3d6383;
  border-radius: 4px;
  max-width: 100px;
  
}

.email-form {
    padding: 8%;
    margin: 6%;
    flex:1;
    background-color: #c0daff;
    color: #6f6f6f;
    width: 90vw;
    flex-direction: column;
    align-items: center;
}

.email-form button, .email-form input {
    background-color:#7c99c1;
    color: #292929;
}

.email-form p {
  margin-top: 30px;
}

.firstPuzzle {
  padding: 8%;
  margin: 6%;
  color: white;
  width: 90%;
  flex-direction: column;
  align-items: center;
}

.menu {
  padding: 2%;
  flex:1;
  height: 85vh;
}

.menu-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.menu .btn {
  background-color: black;
  border-color: #ccc;
  color: #c0daff;
  flex: 0 32%;
  margin-bottom: 2%; /* (100-32*3)/2 */
}

.content {
  padding: 2%;
  flex:1;
  height: 85vh;
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
}

.content .contentIndice {
  flex:1;
  flex-grow:1;
  padding-top: 1%;
}

.content .btn {
  flex:1;
  height: 12vh;
  margin-top: 3%;
  background-color: #c0daff;
  border-color: #ccc;
  color: black;
  width: 90%;
}

.content .btn-disabled {
    flex:1;
    height: 12vh;
    margin-top: 3%;
    background-color: #6f6f6f;
    border-color: #ccc;
    color: black;
    width: 90%;
  }

.content .p {
  margin-top: 5%;
}

.content .buttonGetIndice {
    margin-bottom: 10vh;
}

.compteur {
  font-size: x-large;
}

.chiffre-central-compteur {
  padding: 40px;
  font-size: x-large;
  margin-bottom: 50px;
}

.boup {
  margin-bottom: 30px;
}

.img_full {
  width: 100%;
}

#reponseForm {
    padding: 2%;
    flex:1;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#reponseForm div {
  /*min-height: 10vh;*/
  display: block;
  font-size: x-large;
}

#reponseForm .reponse {
  letter-spacing: 6px;
  font-size: xx-large;
  font-weight: bolder;
  margin-top: 30px;
  margin-bottom: 30px;
}

.vert {
  background-color: #47c516;
  width: 100%;
  height: 100%;
  padding: 11%;
}

select {
  color: #292929;
}

